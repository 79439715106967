<template>
  <div class="columns">
    <div
      class="
        column
        is-12-mobile is-8-tablet is-8-desktop is-6-widescreen is-6-fullhd
      "
    >
      <h1 class="title is-size-1">You have arrived.</h1>
      <p>Huzzah! Greetings travelers!</p>
      <p class="mt-3">
        We, Ryan and Matt, <i>personally</i> invite you to our very own wizard
        tower found in the hidden clouds of the web called the
        <strong>Magister's Manual!</strong>
      </p>
      <p class="mt-3">
        Here you will find many focuses of study including the Fine Arts of
        role-playing games, the whimsical acting of improvisation, and the great
        mystery of mapmaking. You'll find we dive into many different tabletop
        games of all ranges. Some of which might involve fighting dragons,
        others being a superhero who stops evil masterminds, or a member of a
        grand heist trying to steal an ancient tome, or the wizard who hoard
        said tomes.
      </p>
      <p class="mt-3">
        We hope you find our wares enjoyable, entertaining, and inspirational
        for you and your games, regardless if you are a Game Master or a player!
      </p>
    </div>
    <div class="column">
      <!-- <figure class="image is-square">
        <img src="https://via.placeholder.com/3000" />
      </figure> -->
    </div>
  </div>
</template>

<script>
  // - components
  // - mixins
  // - props
  // - data
  // - computed
  // - watch
  // - lifecycle
  // - methods
  // - template

  export default {};
</script>
